interface Interfaces {
  name: string;
  color: string;
  id: number;
}
const EnumPaymentType = [] as unknown as Interfaces[];
EnumPaymentType[1] = { name: 'Bargeld', color: '#636e72', id: 1 };
EnumPaymentType[2] = { name: 'Kreditkarte', color: '#d63031', id: 2 };
EnumPaymentType[3] = { name: 'EC-Karte', color: '#00b894', id: 3 };
EnumPaymentType[4] = { name: 'PayPal', color: '#0984e3', id: 4 };
EnumPaymentType[5] = { name: 'Lastschrift', color: '#ffeaa7', id: 5 };
EnumPaymentType[6] = { name: 'Gutschein', color: '#a29bfe', id: 6 };
EnumPaymentType[7] = { name: 'Online-Bezahlung', color: '#f57c00', id: 7 };
EnumPaymentType[8] = { name: 'Kundengutschein', color: '#fd79a8', id: 8 };
EnumPaymentType[9] = { name: 'Sodexo Restaurantcheque', color: '#e84393', id: 9 };
EnumPaymentType[10] = { name: 'Coupon', color: '#81ecec', id: 10 };
EnumPaymentType[11] = { name: 'Überweisung', color: '#fab1a0', id: 11 };
EnumPaymentType[14] = { name: 'Credit Card On Door Step', color: '#2d3436', id: 14 };
EnumPaymentType[100] = { name: 'Unbekannt', color: '#000000', id: 100 };

export default EnumPaymentType;
