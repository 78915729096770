<template>
  <div>
    <base-sales :enum-interface-type-derived="EnumPaymentType" interface-column-name="interfaceTypeDerived" :sales-data="salesData" :dates.sync="dates"></base-sales>
  </div>
</template>

<script>
import BaseSales from '@/layouts/BaseSales.vue';
import EnumPaymentType from '@/enums/EnumPaymentType';

export default {
  components: {
    BaseSales,
  },
  data: () => ({
    EnumPaymentType,
  }),
  computed: {
    salesData() {
      return this.$store.state.paymentTypeChart;
    },
    dates: {
      get() {
        return this.$store.state.dates;
      },
      set(dates) {
        this.$store.commit('setDates', dates);
      },
    },
  },
  watch: {
    dates() {
      this.$store.commit('deletePaymentTypeChart');
      this.$store.dispatch('getPaymentTypeChart');
    },
  },
};
</script>
